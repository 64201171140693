<template>
    <div class="store-landing">
        <header class="store-landing-header" :class="{ bchollos: $config.public.variant === 'bchollos' }">
            <nav class="store-landing-header__logo">
                <NuxtLink to="/">
                    <img :src="$assets.brand.headerLogo" :alt="'Logo ' + appConfig.public.siteName" />
                </NuxtLink>
            </nav>
        </header>
        <LayoutLinksWarning
            v-if="landing && landing.commissions === 'on'"
            :title="appConfig.public.siteName + ' ' + $lang.pages.landing.commission_on"
        />
        <LayoutLinksWarning
            v-if="landing && landing.commissions === 'free'"
            :title="$lang.pages.landing.commission_free"
        />

        <main class="store-landing__main">
            <section class="store-landing__cover">
                <div class="cover-content">
                    <a
                        v-if="landing.with_digidip"
                        :href="landing.url"
                        rel="nofollow, noopener, noreferrer"
                        target="_blank"
                    >
                        <picture class="cover-content__image">
                            <img
                                v-if="landing.image"
                                :src="landing.image.url || defaultImages.store"
                                :alt="landing.name"
                                :title="landing.name"
                                loading="lazy"
                                :onerror="`this.src='${defaultImages.store}'`"
                            />
                        </picture>
                    </a>
                    <a
                        v-else
                        :href="`/go/${$lang.routes.brands}/${landing.id}`"
                        target="_blank"
                        rel="nofollow"
                    >
                        <picture class="cover-content__image">
                            <img
                                v-if="landing.image"
                                :src="landing.image.url || defaultImages.store"
                                :alt="landing.name"
                                :title="landing.name"
                                loading="lazy"
                                :onerror="`this.src='${defaultImages.store}'`"
                            />
                        </picture>
                    </a>

                    <div v-if="landing.seo" class="flex-grow">
                        <h1 class="cover-content__title">
                            {{ h1 }}
                        </h1>
                    </div>
                </div>
            </section>
            <div class="store-landing__main-list">
                <ul v-if="coupons.results.length" class="store-landing__coupons-list">
                    <li v-for="(item, key) in coupons.results" :key="key" :style="{ order: key + 1 }">
                        <DCardAdmin
                            v-if="item.is_admin"
                            :store-info="landing"
                            :info="item"
                            landing="landing"
                        />
                    </li>
                </ul>
                <MiscNoResults
                    v-else
                    class="container md:max-w-[940px]"
                    mode="discounts"
                    :title="`${$lang.views.storeDetails.we_dont_have} <span class='text-site-primary'>${$lang.views.storeDetails.coupons}</span> ${$lang.views.storeDetails.in_the_store} ${landing.name}, ${$lang.views.storeDetails.will_be_available}`"
                />
            </div>
        </main>
    </div>
</template>

<script lang="ts" setup>
import type { Api } from '~/global'
import { useAuthStore } from '~/store/auth'
import type { Models } from '~/types/models'
import { replacePlaceholders } from '~/util/textFunction'

definePageMeta({
    layout: 'void',
})

const Route = useRoute()

const { currentDate } = useDateFunctions()

const currentDates = currentDate()

const appConfig = useRuntimeConfig()

const AuthStore = useAuthStore()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const result = await useAsyncData('landing-page-data', async () => {
    const landingRes = await $fetch<Api.Responses.Pages.StoreLanding>(
        endpoints.pages.store.landing.replace('_SLUG_', (Route.params.slug as string) || ''),
        {
            method: 'GET',
            headers: buildHeaders(AuthStore.SessionToken || undefined),
            baseURL,
        },
    ).catch((e) => console.log('Error on landing fetch: ', { e }))

    if (!landingRes) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found',
        })
    }

    const couponsRes = await $fetch<Api.Responses.General.IndividualModel<Models.Discount>>(
        endpoints.general.coupons.path,
        {
            method: 'GET',
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
            params: {
                per_page: 1000,
                page: 1,
                'stores[]': landingRes.data.id,
                status: 'active' as 'active',
                order_by: 'latest' as 'latest',
                only_admin: true,
            },
        },
    ).catch((e) => {
        console.log('Error on landing coupons fetch: ', { e })
    })

    return {
        landingRes,
        couponsRes,
    }
})

if (!result.data.value || !result.data.value?.couponsRes || !result.data.value.landingRes) {
    throw createError({
        statusCode: 404,
        message: 'Página no encontrada',
    })
}
const landing = ref(result.data.value.landingRes.data)
const coupons = ref(result.data.value.couponsRes.data)

const { defaultImages } = useVariantsDictionary()

const {
    public: { origin, siteName },
} = useRuntimeConfig()
const [title, description, h1, h2] = replacePlaceholders(
    [
        [':store', landing.value.name || ''],
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':day', String(currentDates.day) || ''],
        [':coupons', String(coupons.value.meta.total || 0)],
        [':site', siteName || ''],
    ],
    [
        landing.value.seo?.title || '',
        landing.value.seo?.description || '',
        landing.value.seo?.h1 || '',
        landing.value.seo?.h2 || '',
    ],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    ogImageSecureUrl: landing.value.image?.url || '',
    ogImageUrl: landing.value.image?.url || '',
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
    robots: 'noindex, nofollow',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.store-landing {
    @apply relative min-h-screen overflow-x-hidden bg-site-background;

    &-header {
        @apply h-16 w-full bg-site-primary;
        &.bchollos {
            @apply bg-site-secondary;
        }

        &__logo {
            @apply container flex h-full items-center justify-center;

            a {
                @apply block h-[30px] w-[200px];
                img {
                    @apply h-full w-full object-contain;
                }
            }
        }
    }
    &__cover {
        /*  @apply h-[110px] lg:h-[140px]; */
        .cover-content {
            @apply container flex items-center gap-3 py-3 text-black md:max-w-[940px];
            &__image {
                @apply flex h-20 w-20 items-center overflow-hidden rounded-full border-4 border-white bg-white lg:h-28 lg:w-28;
                img {
                    @apply h-full w-full object-contain;
                }
            }
            &__title {
                @apply mb-1 text-xl font-medium sm:text-2xl;
            }
        }
    }
    &__coupons-list {
        @apply container flex flex-col md:max-w-[940px];
        li {
            @apply mb-5;
        }
    }

    &-footer {
        @apply w-full;
        ol {
            @apply container px-4 md:max-w-[940px];
        }
    }
}
</style>
